@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 1280px) {
  .navbar {
    justify-content: space-between;
    cursor: pointer;
    position: sticky;
  }
}
body {
  font-family: 'Plus Jakarta Sans';
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  width: 100%;
}
