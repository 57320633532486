.emailForm {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 30%;
  margin: 0 auto;
}

input,
textarea {
  margin: 10px 0px;
  justify-content: center;
  border-radius: 20px;
  height: 50px;
}
textarea {
  height: 100px;
}
.App {
  height: 100%;
  overflow-x: hidden;
  padding: 1rem;
}
